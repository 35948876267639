import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';


import {
    IntroHolder,
    TwoColumns,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const Page = ({ data }) => (
    <InnerPage site="hda" title="60 Jahre Haus der Andacht" description="" image={data.introImage.childImageSharp.fluid.src}>
        <IntroHolder
            title="60 Jahre Haus der Andacht"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Haus der Andacht',
                'current-item': '60 Jahre Haus der Andacht',
            }}
        />

        <TwoColumns>
            <div id="content">
                <p>
                    Als das Haus der Andacht in Hofheim bei Frankfurt am Main am 4. Juli 1964 eingeweiht wurde, wurde
                    ein bedeutendes Kapitel in der Geschichte der europäischen und weltweiten Bahá'í-Gemeinde
                    aufgeschlagen.
                </p>
                <p>
                    Dieses strahlende Symbol der Einheit und Geistigkeit hat in diesen 60 Jahren unzählige Menschen
                    jeglichen Hintergrunds angezogen. Das Haus der Andacht ist ein Ort des Gebets und der Besinnung und
                    wurde geschaffen, um die Menschheit zu vereinen und eine gemeinsame Anbetungsstätte für Menschen
                    aller Glaubensrichtungen zu sein. Es ist ein Ort, an dem Menschen zusammenkommen, um die Liebe
                    Gottes zu spüren und auszudrücken und sich auf ihren Beitrag zur Besserung der Gesellschaft zu
                    besinnen.
                </p>
                <p>
                    Alle sind herzlich zu den Feierlichkeiten anlässlich des 60. Jahrestages der Einweihung des Hauses
                    der Andacht in Hofheim-Langenhain eingeladen. Dieses besondere Jubiläum wird am 6. und 7. Juli 2024
                    für alle stattfinden und einen Höhepunkt in der Geschichte des Hauses der Andacht markieren.
                </p>
                <p>
                    Zwischen 11 und 19 Uhr sind alle herzlich eingeladen, dieses besondere Jubiläum zu feiern und
                    gemeinsam mit weiteren Gästen aus dem In- und Ausland die besondere Atmosphäre am Haus der Andacht
                    zu erleben und sich bei Andachten, Ausstellungen, Künstlerischen Darbietungen und Erfrischungen
                    auszutauschen.
                </p>
                <h4>Datum & Zeit:</h4>
                <p>
                    6. & 7. Juli 2024<br />
                    Jeweils 11:00 bis 19:00 Uhr
                </p>
                <h4>Adresse</h4>
                <p>
                    Eppsteiner Str. 95<br />
                    D-65719 Hofheim-Langenhain
                </p>
                <h4>Lageplan</h4>
                <Img
                    fluid={data.lageplanImage.childImageSharp.fluid}
                />
                <br />
                <br />
                <h4>Programm</h4>
                <p>
                    <strong>Samstag</strong><br />
                    <strong>11:30 - 12:00 Uhr</strong> Willkommen<br />
                    <strong>12:00 - 13:00 Uhr</strong> Omid Gollmer<br />
                    <strong>13:00 - 13:30 Uhr</strong> Familienandacht im Haus der Andacht<br />
                    <strong>13:30 - 15:00 Uhr</strong> Maryam & Band, Karaoke<br />
                    <strong>15:00 - 15:30 Uhr</strong> Andacht im Haus der Andacht, mit Stimmen Bahás<br />
                    <strong>15:30 - 17:00 Uhr</strong> Gesangsverein 1844 Langenhain e.V., Instrumentalduo CHIARA<br />
                    <strong>17:00 - 17:30 Uhr</strong> Andacht im Haus der Andacht, mit Stimmen Bahás (<a href="https://www.youtube.com/bahaide">wird gestreamt</a>)<br />
                    <strong>17:30 - 19:00 Uhr</strong> Onni Aflatuni, Corrado Todrani, Musikduo „Rosen der Liebe"<br />
                    <strong>19:30 - 20:00 Uhr</strong> Vortrag „Die Geschichte von Herrn Engelsman“ im Besucherzentrum<br />
                    <strong>20:00 - 22:30 Uhr</strong> Musikprogramm, Open Stage<br />
                    <strong>22:00 - 22:30 Uhr</strong> Andacht im Haus der Andacht, mit Stimmen Bahás<br />
                    <br />
                    <strong>Sonntag</strong><br />
                    <strong>11:30 - 12:00 Uhr</strong> Willkommen<br />
                    <strong>12:00 - 13:00 Uhr</strong> Omid Gollmer<br />
                    <strong>13:00 - 13:30 Uhr</strong> Andacht im Haus der Andacht, mit Stimmen Bahás<br />
                    <strong>13:30 - 15:00 Uhr</strong> Meiers Paradise, Incantare Akkordeonochester und mehr e.V.<br />
                    <strong>15:00 - 15:30 Uhr</strong> Andacht im Haus der Andacht, mit Stimmen Bahás<br />
                    <strong>15:30 - 17:00 Uhr</strong> Akkordeon-Orchester Langenhain e.V., Instrumentalduo CHIARA, Duo Lua & Riaz<br />
                    <strong>17:00 - 17:30 Uhr</strong> Familienandacht im Haus der Andacht<br />
                    <strong>17:30 - 19:00 Uhr</strong> Musikprogramm, Open Stage<br />
                </p>

            <Link to="/dokumente/60-Jahre-Haus-der-Andacht-Programm.pdf">
                Programm (PDF)
            </Link>
        </div>
    </TwoColumns>
</InnerPage>
)
;

export default Page;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "hausderandacht-drohnenaufnahme-jubilaeum.jpg" }) {
            ...fluidImage
        }
        lageplanImage: file(relativePath: { eq: "HdA-Lageplan.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
        }
    }
`;
